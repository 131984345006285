<template>

  <div class="chart">

    <div class="rfs" ref="chartCmp"></div>
    
  </div>

  
</template>

<script>
 
export default {   
  
  props: [
    'total',
    'success',
    'hint'
  ],
  data () {
    return {         
    }
  },
  mounted() {     
    this.draw(); 
  },
  methods: { 


		replaceVariablesInText(text, attrs) {
			for (var k in attrs) {
				text = text.replace('{' + k + '}', attrs[k]);
			}
			return text;
		},
		makeSVG(tag, attrs, into) {
			var el = document.createElementNS('http://www.w3.org/2000/svg', tag);
			for (var k in attrs) {
				if (attrs.hasOwnProperty(k)) el.setAttribute(k, attrs[k]); 
			}
			if(into){
				var textNode = document.createTextNode(into);
				el.appendChild(textNode);
			}
			return el;
		}, 
		draw() {
				
			var total = this.total;
			var success = this.success; 
			var hint = this.hint; 
			var percent = Math.round(success*100/total);

			var pieData = [];
		
			for(var i = 0; i < total; i++){
				if(i < success){
					
					if(percent < 20){
						pieData.push('#ff7849');
					}else if(percent < 60){
						pieData.push('#ffad5a');
					}else{
						pieData.push('#b5ed6f');
					}
					
				}else{
					pieData.push('#e0e1e2');
				}
				
			}

			var svg = this.makeSVG('svg', {
				'viewbox': '0 0 150 150',
				'xmlns': 'http://www.w3.org/2000/svg' 
			});
  
			// console.log(pieData);
			// var total = pieData.reduce(function (accu, that) { return that + accu; }, 0);
			var sectorAngleArr = pieData.map(function () { return 360 * 1 / total; });

			var startAngle = 0;
			var endAngle = -90;
			for (var i=0; i<sectorAngleArr.length; i++){
      
        startAngle = endAngle;
        endAngle = startAngle + sectorAngleArr[i];

        var x1,x2,y1,y2;

        x1 = parseInt(Math.round(75 + 72.5*Math.cos(Math.PI*startAngle/180)));
        y1 = parseInt(Math.round(75 + 72.5*Math.sin(Math.PI*startAngle/180)));

        x2 = parseInt(Math.round(75 + 72.5*Math.cos(Math.PI*endAngle/180)));
        y2 = parseInt(Math.round(75 + 72.5*Math.sin(Math.PI*endAngle/180)));

        var d = "M75,75  L" + x1 + "," + y1 + "  A72.5,72.5 0 " + 
                ((endAngle-startAngle > 180) ? 1 : 0) + ",1 " + x2 + "," + y2 + " z";

        if(sectorAngleArr.length > 1){
  
          // var c = parseInt(i / sectorAngleArr.length * 360);

          var arc = this.makeSVG("path", {
            d: d, 
            fill: pieData[i],
            stroke: "white", 
            'stroke-width': "3"
          });			

        }else{

          var arc = this.makeSVG('circle', {
            'cx': 75,
            'cy': 75,
            'r': 72.5,
            'fill': pieData[i]
          });  
        }
        
        svg.appendChild(arc);
              
      }


      var el_circle = this.makeSVG('circle', {
        'cx': 75,
        'cy': 75,
        'r': 36,
        'fill': 'white'
      });
      svg.appendChild(el_circle);
      

      var el_title = this.makeSVG('text', {
        'x': 75,
        'y': 75,						
        'text-anchor': 'middle',
        'font-size': '20',
        'color': 'black'
      }, percent + '%');
      svg.appendChild(el_title);

      var el_hint = this.makeSVG('text', {
        'x': 75,
        'y': 93,						
        'width': 30,						
        'text-anchor': 'middle',
        'font-size': '12',
        'color': 'black'
      }, this.replaceVariablesInText(hint, {
        total: total,
        success: success
      }));
      svg.appendChild(el_hint);

      // console.log('this.$refs');
      // console.log(this.$refs);
      // console.log(this.$refs.chartCmp);
      this.$refs.chartCmp.appendChild(svg);
      // element.appendChild(svg);
  
    }


  }
}
</script>

<style scoped>
 
 
 
 

</style>
